import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBpH06ou-L5Ua44PdS2OVY3KVZXzinkwsk",
    authDomain: "asmbot-69455.firebaseapp.com",
    databaseURL: "https://asmbot-69455.firebaseio.com",
    projectId: "asmbot-69455",
    storageBucket: "asmbot-69455.appspot.com",
    messagingSenderId: "636695844453",
    appId: "1:636695844453:web:0aecffbbb0384242"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

export { db };
